import React from 'react'
import ReactDOMServer from 'react-dom/server'
import isEqual from 'lodash/isEqual'
import Helmet from 'react-helmet'
import {
  htmlToReactParser,
  isValidNode,
  processingInstructions
} from './parseShortHandContent'
import {
  addGoogleAnalyticsPageData,
  createArticleData
} from '../../utils/googleAnalyticsEvents'
import DateTimeService from '../../utils/common'
import { getFocalPointForArticle } from '../../utils/getImgixUrl'
import signImgix from '../../utils/signImgix'

class ShortHandArticle extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState)
  }

  getContent(article) {
    try {
      let htmlNodes = htmlToReactParser.parseWithInstructions(
        article.content,
        isValidNode,
        processingInstructions
      )

      htmlNodes = htmlNodes ? htmlNodes.find((item) => item !== false) : ''

      const reactHtml = ReactDOMServer.renderToStaticMarkup(htmlNodes)

      return (
        <div
          className="shorthandWrapper"
          dangerouslySetInnerHTML={{
            __html: `${reactHtml}`
          }}
        ></div>
      )
    } catch (e) {
      console.log('Error in getContent ', e)
      return (
        <div>
          <p>Something went wrong, Please try again later.</p>
        </div>
      )
    }
  }

  componentDidMount() {
    ;[].slice
      .call(document.querySelectorAll('[data-shorthand]'))
      .forEach(function (embed) {
        var url = embed.getAttribute('data-shorthand')
        if (url.indexOf('http') === -1) url = 'https://' + url
        var script = document.createElement('script')
        script.src = url.replace(/\/?$/, '/embed.js')
        embed.parentElement.insertBefore(script, embed)
      })

    const articlePageData = createArticleData(this.props.article)
    addGoogleAnalyticsPageData(articlePageData)
  }

  renderImageMeta = () => {
    const { article } = this.props

    // for both twitter and FB its 1200*630
    const fixedWidth = 1200
    const fixedHeight = 630
    const fp = getFocalPointForArticle(article.mainImage)
    const fpString = fp
      ? `&fp-x=${fp.x}&fp-y=${fp.y}&fp-z=${fp.z}&fit=crop&crop=focalpoint`
      : ''

    const imageUrl = signImgix(
      `${article.mainImageUrl}?w=${fixedWidth}&h=${fixedHeight}&dpr=2&q=90&fit=crop${fpString}`
    )

    return (
      <Helmet>
        <meta property="og:image" itemProp="image" content={imageUrl} />
        <meta property="og:image:url" itemProp="image" content={imageUrl} />
        <meta
          property="og:image:secure_url"
          itemProp="image"
          content={imageUrl}
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:alt" content={article.title} />
        <meta property="og:image:type" content="image/jpeg" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:image" content={imageUrl} />
      </Helmet>
    )
  }

  render() {
    const { article } = this.props
    const articleUrl = `${process.env.GATSBY_BASE_URL}${article.slug}`
    const articleTitle = `${article.seoTitle || article.title} | الشرق للأخبار`
    const articleDescription = `${article.seoDescription} الشرق للأخبار`

    return (
      <div data-article-id={article.id}>
        {this.getContent(article)}
        {article.mainImageUrl && this.renderImageMeta()}

        <Helmet
          htmlAttributes={{
            lang: 'ar'
          }}
        >
          <head prefix="og: http://ogp.me/ns# article: http://ogp.me/ns/article#" />
          <title>{articleTitle}</title>
          <meta name="description" content={articleDescription} />
          <meta property="fb:app_id" content={'236955207547425'} />
          <meta property="og:url" content={articleUrl} />
          <link rel="canonical" href={encodeURI(articleUrl)} />

          <meta property="og:type" content="article" />
          <meta property="og:locale" content="ar_AR" />

          {article.authors && article.authors.length > 0 ? (
            <>
              <meta
                property="article:author"
                content={article.authors.map((author) => author.name)}
              />
              <meta
                property="og:author"
                content={article.authors.map((author) => author.name)}
              />
            </>
          ) : null}

          {article.categories && article.categories.length > 0 ? (
            <>
              <meta property="article:tag" content={article.categories} />
              <meta
                property="article:section"
                content={article.categories[0]}
              />
            </>
          ) : null}

          {article.keywordsAr && article.keywordsAr.length > 0 ? (
            <meta name="keywords" content={article.keywordsAr} />
          ) : null}

          <meta
            property="article:published_time"
            content={DateTimeService.formattedDateTime(
              article.publishedAt || article.updatedAt || article.createdAt
            )}
          />

          {(article.updatedAt || article.createdAt) && (
            <meta
              property="article:modified_time"
              content={DateTimeService.formattedDateTime(
                article.updatedAt || article.createdAt
              )}
            />
          )}

          <meta property="og:site_name" content="Asharq" />

          <meta property="og:title" content={articleTitle} />

          <meta property="og:description" content={articleDescription} />

          <meta property="twitter:url" content={articleUrl} />
          <meta property="twitter:title" content={articleTitle} />
          <meta property="twitter:description" content={articleDescription} />
          <meta property="twitter:site" content="@AsharqNews" />
        </Helmet>
      </div>
    )
  }
}

export default ShortHandArticle
