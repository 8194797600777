import React from 'react'
import { Parser as HtmlToReactParser } from 'html-to-react'

const htmlToReactParser = new HtmlToReactParser()
const isValidNode = () => true

const processingInstructions = [
  {
    replaceChildren: true,
    shouldProcessNode: function (node) {
      const { attribs } = node
      return (
        node.type === 'tag' && attribs && attribs.id === 'shorthand-embed-code'
      )
    },
    processNode: function (node) {
      const { children } = node
      if (children && children.length > 0) {
        const scriptElement = children.find((ele) => ele.type === 'script')

        if (scriptElement) {
          const { attribs = {} } = scriptElement
          const dataId = attribs['data-id'] || ''
          // convert script to div to force manual call for shorthand stories.
          // auto loading of script has rendering issues when
          // 1. navigating from homepage
          // 2. using browser next/prev button to navigate in between pages
          if (attribs.src) {
            return (
              <div
                data-shorthand={attribs.src.replace('/embed.js', '')}
                data-id={dataId}
              />
            )
          }
        }
      }
      return null
    }
  }
]

export { htmlToReactParser, isValidNode, processingInstructions }
