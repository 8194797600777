import styled from "styled-components"
import { device } from "../../utils/screenSizes"

export const PrivacyPopupContainer = styled.div`
  position: fixed;
  bottom: 40px;
  left: 40px;
  width: 400px;
  height: auto;
  background: #252e31;
  padding: 20px;
  z-index: 1006;

  display: ${props => (props.isVisible ? "inherit" : "none")};

  -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);

  @media ${device.s} {
    padding: 10px 20px;
    bottom: 0;
    left: 0;
    width: calc(100vw - 40px);
  }
`

export const PrivacyPopupTitle = styled.h3`
  margin: 0;
  padding: 0;
  color: #fff;
`

export const PrivacyPopupText = styled.p`
  font-size: 0.9em;
  color: #bec0c1;
`

export const PrivacyPopupFooter = styled.div`
  padding: 10px 0;

  a {
    color: #fff;
    text-decoration: none;
    margin: 0 20px;
    font-size: 0.85em;
  }
`

export const PrivacyPopupAction = styled.button`
  background: #52c368;
  color: #fff;
  border: none;
  outline: none;
  padding: 10px 20px;
  border-radius: 0 10px;
  cursor: pointer;

  &:hover {
    background: #44b059;
  }
`
