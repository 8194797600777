import React from 'react'
import Helmet from 'react-helmet'
import PageTransition from 'gatsby-plugin-page-transitions'
import { isEmpty } from 'lodash'

import Layout from '../components/layout'
import ShorthandLayout from '../components/shorthandLayout'
// import SEO from "../components/seo"

import Article from '../components/article/article'
import ShortHandArticle from '../components/article/shortHandArticle'
import getImgixUrl from '../utils/getImgixUrl'
import DateTimeService from '../utils/common'

const ArticleTemplate = ({ pageContext, location }) => {
  let article = pageContext.data
  const layoutType = article.isPhotoArticle ? 'fullWidth' : 'article'

  const imgUrl = getImgixUrl({
    src: article.mainImageUrl,
    imageQuality: 90,
    imageDpr: 2,
    imageMeta: article.mainImage,
    cardType: 3,
    newWidth: 1200,
    newHeight: 628
  }).url

  const escape = (str) => (str ? str.replace(/"/g, '\\"') : '')
  const datePublished =
    article.publishedAt || article.updatedAt || article.createdAt
  const formattedDatePublished =
    DateTimeService.formattedDateTime(datePublished)
  const formattedUpdatedDate = DateTimeService.formattedDateTime(
    article.updatedAt
  )
  const articleUrl = `${process.env.GATSBY_BASE_URL}${article.slug}`
  const sanitizeHtmlContent = (content) => {
    if (content) {
      return content
        .replace(/(<([^>]+)>)/gi, '')
        .replace(/\\?"/g, '\\"')
        .replace(/\n/g, '')
        .replace(/\\\s/g, '\\\\')
    }
    return ''
  }
  let articleBody = sanitizeHtmlContent(article.contentAr)

  if (article.isLiveArticle && !isEmpty(article.subArticles)) {
    let subArticleBody = ''
    article.subArticles.forEach((subArticle) => {
      subArticleBody += sanitizeHtmlContent(subArticle.contentAr)
    })
    if (subArticleBody) {
      articleBody = subArticleBody
    }
  }

  const renderArticleLDJson = () => {
    return (
      <Helmet>
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "NewsArticle",
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": "${articleUrl}"
            },
            "headline": "${escape(article.title)}",
            "image": {
              "@type": "ImageObject",
              "url": "${article.mainImageUrl ? imgUrl : article.mainVideoUrl}",
              "width": 1200,
              "height": 818
            },
            ${article.mainVideoUrl ? `"video": "${article.mainVideoUrl}",` : ''}
            "articleSection": [
              ${article.categories.map((cat) => `"${cat}"`).join(',')}
            ],
            "thumbnailUrl": "${
              article.mainImageUrl ? imgUrl : article.mainVideoUrl
            }",
            "datePublished": "${formattedDatePublished}",
            "dateModified": "${formattedUpdatedDate}",
            "author": {
              "@type": "Organization",
              "name": "${escape(article.authors[0]?.name)}"
            },
            "publisher": {
              "@type": "Organization",
              "name": "Asharq News الشرق",
              "logo": {
                "@type": "ImageObject",
                "url": "https://img.asharq.com/1401b346-d7f2-4b46-871f-b39fcf1b58a0.png?ixlib=js-2.3.1&s=5ea62a24e83b2792c87526e0be07d8ce"
              }
            },
            "articleBody": "${articleBody}"
          }`}
        </script>
      </Helmet>
    )
  }

  if (article.isShortHandArticle) {
    // article body for shorthand will have only script tag, actual content is from shorthand cms
    articleBody = ''
    return (
      <ShorthandLayout>
        <ShortHandArticle article={article} location={location} />
        {renderArticleLDJson()}
      </ShorthandLayout>
    )
  }

  return (
    <PageTransition>
      <Layout
        className={layoutType}
        location={location}
        title={''}
        isArticle
        lang={article.lang}
        allowPopUp
        articleId={article.id}
        layoutType={layoutType}
      >
        <Article article={article} location={location} grid={1} />

        {renderArticleLDJson()}
      </Layout>
    </PageTransition>
  )
}

export default ArticleTemplate
