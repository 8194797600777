import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'

import {
  PrivacyPopupContainer,
  PrivacyPopupText,
  PrivacyPopupFooter,
  PrivacyPopupAction
} from './PrivacyPopup.styled'

const PrivacyPopup = () => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (!localStorage.getItem('acceptPrivacyPolicy')) {
      setIsVisible(true)
    }
  }, [])

  const handleAcceptPrivacyPolicy = () => {

    // G360 Event for consent
    dataLayer.push({
      event: 'asEvent',
      analyticsEventLabel: 'Consent',
      eventCategory: 'Consent',
      eventAction: 'Choice',
      eventLabel: 'Yes'
    })

    localStorage.setItem('acceptPrivacyPolicy', true)
    setIsVisible(false)
  }

  return (
    <PrivacyPopupContainer isVisible={isVisible}>
      <PrivacyPopupText>
        نستخدم في موقعنا ملف تعريف الارتباط (كوكيز)، لعدة أسباب، منها تقديم ما
        يهمك من مواضيع، وكذلك تأمين سلامة الموقع والأمان فيه، منحكم تجربة قريبة
        على ما اعدتم عليه في مواقع التواصل الاجتماعي، وكذلك تحليل طريقة استخدام
        موقعنا من قبل المستخدمين والقراء.
      </PrivacyPopupText>

      <PrivacyPopupFooter>
        <PrivacyPopupAction onClick={() => handleAcceptPrivacyPolicy()}>
          موافق
        </PrivacyPopupAction>
        <Link to="/ar/privacy-policy">اعرف أكثر</Link>
      </PrivacyPopupFooter>
    </PrivacyPopupContainer>
  )
}

export default PrivacyPopup
