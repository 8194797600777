import React from 'react'
import ReduxToastr from 'react-redux-toastr'
import Helmet from 'react-helmet'

import FingerPrintManager from '../utils/FingerPrintManager'
import PrivacyPopup from '../components/PrivacyPopup/PrivacyPopup'
import BreakingNews from '../components/breakingNews/BreakingNews'

FingerPrintManager.init()

const ShorthandLayout = ({ lang, children }) => {
  return (
    <>
      <ReduxToastr
        timeOut={3000}
        preventDuplicates
        position="top-right"
        showCloseButton={false}
      />
      <div
        dir={lang ? (lang === 'ar' ? 'rtl' : 'ltr') : 'rtl'}
        lang={lang || 'ar'}
      >
        <main>{children}</main>
        <PrivacyPopup />
        <BreakingNews />
        <Helmet>
          <link
            rel="preload"
            href="https://cdnjs.cloudflare.com/ajax/libs/toastr.js/latest/css/toastr.min.css"
            as="style"
            onLoad="this.onload=null;this.rel='stylesheet'"
          />
          <noscript>
            {`
              <link
                rel="stylesheet"
                href="https://cdnjs.cloudflare.com/ajax/libs/toastr.js/latest/css/toastr.min.css"
              />
            `}
          </noscript>
          <script
            type="text/javascript"
            src="https://cdnjs.cloudflare.com/ajax/libs/toastr.js/latest/js/toastr.min.js"
            async
            defer
          />
          <meta name="theme-color" content="#000000" />
          <meta
            property="fb:pages"
            content={`${process.env.GATSBY_FACEBOOK_PAGE_ID}`}
          />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, viewport-fit=cover"
          />
        </Helmet>
      </div>
    </>
  )
}

export default ShorthandLayout
